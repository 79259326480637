import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import SearchBar from "../SearchBar/searchBar";

import { useTableState } from "../../../contexts/tableContext";

const TableToolbarSelected = (props) => {
  const classes = useStyles();
  const { hasSearch, hasFilters } = useTableState();

  return (
    <Box className={classes.toolbarControls}>
      {hasSearch ? (
        <SearchBar
          handleSearch={props.handleSearch}
          handleSearchClear={props.handleSearchClear}
          placeholder={props.placeholderSearch}
          controlledSearch={props.controlledSearch}
        />
      ) : null}
      {hasFilters ? (
        <Box className={classes.toolbarFilters}>{props.children}</Box>
      ) : null}
    </Box>
  );
};

export default TableToolbarSelected;
