import React from "react";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import RemoveIcon from "@material-ui/icons/FileCopy";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import useStyles from "./styles";

const ConfirmationDialog = (props) => {
  const { open, onClose, onConfirm, title, content, body } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
    >
      <DialogContent className={classes.wrapperDialog}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.wrapperDialogClose}
        >
          <CloseIcon className={classes.iconDialogClose} />
        </IconButton>
        <RemoveIcon className={classes.iconDialog} />
        <Typography variant="h6" gutterBottom className={classes.titleDialog}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.contentDialog}
        >
          {content}
        </Typography>
        <Box>{body}</Box>
      </DialogContent>
      <DialogActions className={classes.wrapperDialogAction}>
        <ButtonBase
          onClick={onClose}
          className={classes.buttonCancel}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          onClick={onConfirm}
          className={classes.buttonConfirm}
          color="secondary"
        >
          Confirm
        </ButtonBase>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
