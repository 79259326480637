import React, { useState, useEffect } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useFilterState } from "contexts/filterContext";
import useStyles from "./styles";

const SearchBar = (props) => {
  const [stateValue, setStateValue] = useState("");
  const { searchString } = useFilterState();
  const classes = useStyles();
  const { handleSearch, placeholder, controlledSearch } = props;

  useEffect(() => {
    if (searchString !== "") {
      setStateValue(searchString);
    } else {
      setStateValue("");
    }
  }, [searchString]);

  const handleChange = (event) => {
    setStateValue(event.target.value);
    if (event.target.value === "") {
      props.handleSearchClear();
    }
  };

  const handleClear = () => {
    setStateValue("");
    props.handleSearchClear();
  };

  return (
    <OutlinedInput
      name="searchField"
      className={classes.inputField}
      onKeyPress={(ev) => handleSearch(ev, stateValue)}
      value={stateValue}
      onChange={handleChange}
      inputProps={{ autoComplete: "off" }}
      placeholder={placeholder ? placeholder : undefined}
      endAdornment={
        <InputAdornment position="end">
          {searchString !== "" || (controlledSearch && stateValue !== "") ? (
            <IconButton
              aria-label="close"
              className={classes.clearSearchButton}
              onClick={handleClear}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <SearchIcon
              className={classes.searchIcon}
              onClick={(ev) => handleSearch(ev, stateValue)}
            />
          )}
        </InputAdornment>
      }
    />
  );
};

export default SearchBar;
