import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflowY: "scroll",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: 10,
  },
  containerMain: {
    backgroundColor: "#f8f8f8",
    height: "100%",
    display: "flex",
    position: "relative",
  },
  containerMainInformation: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 45,
    },
  },
  containerInformation: {
    padding: theme.spacing(2),
    width: "100%",
  },
  containerInformationActions: {
    backgroundColor: "#ececec",
    padding: 20,
    width: "100%",
    bottom: 0,
    position: "sticky",
    // paddingBottom: 1000,
    // marginBottom: -1000,
    overflow: "hidden",
    "& button": {
      padding: "7px 5px 7px 5px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      paddingBottom: 10,
      marginBottom: 0,
    },
  },
  containerBody: {
    padding: 20,
  },
  containerSidebar: {
    backgroundColor: "#f8f8f8",
    padding: 20,
    paddingBottom: 80,
  },
  titleBody: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitleBody: {
    fontSize: 18,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  containerSubtitle: {
    "& > span:last-child": {
      borderRight: "0 !important",
    },
  },
  containerSubtitleItem: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
    paddingRight: 5,
    borderRight: "1px solid #747474",
  },
  containerTimekeeping: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
    marginBottom: 30,
  },
  containerTimekeepingClock: {
    paddingTop: 40,
    paddingBottom: 40,
    position: "relative",
  },
  containerBodyTaskList: {
    marginTop: 40,
  },
  containerActionButtons: {
    marginTop: 10,
    marginBottom: 12,
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
  },
  buttonLink: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    width: "100%",
    display: "block",
    borderRadius: 4,
    minWidth: 64,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
      backgroundColor: "rgba(23, 69, 122, 0.08)",
    },
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonOutlinedSuccess: {
    color: "#9cbb65",
    background: "#ffffff",
  },
  buttonPrimary: {
    background: "#4f98bc !important",
  },
  buttonPin: {
    background: "#8f0aa1 !important",
    "&::after": {
      top: "97%",
      left: 20,
      border: "solid transparent",
      content: "''",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderTopColor: "#8f0aa1",
      borderWidth: "8px",
      marginLeft: "-8px",
      zIndex: 5,
    },
  },
  buttonAdd: {
    background: "#4f98bc !important",
    width: 160,
  },
  buttonUpload: {
    background: "#4f98bc !important",
    width: 160,
    marginTop: 10,
  },
  containerUploadFile: {
    textAlign: "right",
  },
  containerSignature: {
    padding: 10,
    marginBottom: 30,
  },
  containerLocation: {
    padding: 10,
  },
  containerLocationBtn: {
    marginTop: 30,
  },
  iconLarge: {
    fontSize: 36,
  },
  textTimer: {
    fontSize: 18,
    fontWeight: "normal",
    marginTop: 18,
    marginLeft: 5,
  },
  taskItemFieldset: {
    width: "100%",
  },
  taskItemContainer: {
    marginTop: "10px !important",
    "& > label:last-child": {
      borderBottom: "1px solid #f8f8f8",
    },
  },
  taskItem: {
    borderTop: "1px solid #f8f8f8",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
    "& .MuiFormControlLabel-label": {
      display: "block",
      width: "100%",
    },
    "& .Mui-checked + span": {
      textDecoration: "line-through",
    },
  },
  taskItemAdd: {
    backgroundColor: "#ececec",
    "& > .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  taskLabel: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 12,
    position: "relative",
  },
  boxActions: {
    marginTop: 10,
    textAlign: "right",
  },
  boxActionsComment: {
    marginTop: 10,
    textAlign: "right",
    marginRight: 8,
  },
  inputNewTask: {
    width: "100%",
    "& > input": {
      backgroundColor: "#ececec",
      width: "100%",
    },
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
      borderBottom: "2px solid",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  billingTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  listItemComment: {
    backgroundColor: "#ececec",
    alignItems: "flex-start",
  },
  containerComments: {
    marginTop: 20,
    position: "relative",
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
  },
  inputEditComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
    width: "98%",
  },
  buttonOutlinedComment: {
    marginRight: theme.spacing(1),
  },
  containerActivity: {
    marginTop: 20,
    maxHeight: 500,
    overflowY: "auto",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionDefault: {
    color: "#4f98bc",
    marginTop: 20,
    marginBottom: 30,
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  wrapperEdit: {
    position: "absolute",
    right: 40,
    top: 5,
  },
  wrapperIcon: {
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
  iconOptions: {
    width: 18,
    height: 18,
  },
  titleDialog: {
    textAlign: "center",
    marginBottom: 20,
    fontSize: 18,
    "& > svg": {
      position: "relative",
      bottom: "-5px",
    },
  },
  sigCanvas: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    height: 85,
  },
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  containerStatus: {
    display: "inline-block",
    top: "-3px",
    position: "relative",
    marginLeft: 10,
    "& > .MuiChip-root": {
      height: 21,
      borderRadius: 3,
      fontSize: 10,
      minWidth: 110,
      marginLeft: 5,
      fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: -5,
    },
  },
  chipPrimary: {
    backgroundColor: "#78c1ce !important",
    color: "#fff !important",
  },
  chipDark: {
    backgroundColor: "#747474 !important",
    color: "#fff !important",
  },
  chipExternalPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontWeight: "bold",
    height: 21,
    borderRadius: 3,
    fontSize: 10,
    minWidth: 110,
    marginBottom: theme.spacing(1),
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
  },
  inputLabel: {
    fontSize: "14px !important",
    fontWeight: "bold !important",
    marginBottom: 5,
  },
  containerPriority: {
    marginTop: 10,
    marginBottom: 15,
  },
  dialogHeader: {
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        margin: "0 auto",
      },
    },
  },
  dialogHeaderTimekeeping: {
    marginTop: 20,
    marginBottom: 5,
  },
  containerDialog: {
    minHeight: 400,
  },
  tableDialog: {
    marginTop: 30,
    "& td": {
      border: "none",
    },
    "& th": {
      border: "none",
      background: "#ffffff",
    },
    "& tr:nth-child(odd)": {
      background: "#f8f8f8",
    },
  },
  tableHeadDialog: {
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
  },
  formContainerDialog: {
    padding: "0 75px 0 75px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  formIconDialog: {
    textAlign: "center",
  },
  formTitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  formSubtitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "normal",
    marginBottom: 10,
  },
  containerFile: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
    marginBottom: 30,
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
    },
  },
  listText: {
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
      paddingRight: 0,
    },
  },
  listAction: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      position: "relative !important",
      textAlign: "right",
    },
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important",
    },
  },
  containerSchedule: {
    backgroundColor: "#f8f8f8",
    marginTop: 30,
    marginBottom: 30,
    padding: 15,
  },
  containerScheduleCalendar: {
    height: 500,
    "& .rbc-day-slot": {
      backgroundColor: "#ffffff",
    },
  },
  eventSchedule: {
    backgroundColor: "#78c1ce !important",
    color: "#4d4d4d",
    fontSize: 12,
    border: "none !important",
    borderRadius: "0",
  },
  locationClock: {
    position: "absolute",
    top: -28,
    right: 0,
  },
  scheduleViewOptions: {
    textAlign: "right",
  },
  scheduleViewContainer: {
    "&  .MuiListItem-root": {
      marginLeft: 0,
    },
    "& .MuiListItemIcon-root": {
      minWidth: 35,
    },
    "& .MuiListItemText-root .MuiTypography-root": {
      fontSize: 14,
    },
  },
  scheduleViewTitle: {
    fontSize: 18,
  },
  scheduleViewDateTitle: {
    fontSize: 14,
    fontWeight: "normal",
  },
  scheduleBadge: {
    width: 11,
    height: 11,
    backgroundColor: "#78c1ce",
    borderRadius: 2,
    position: "absolute",
    top: 12,
    left: 20,
  },
  imgSignature: {
    maxWidth: "100%",
  },
  avatarOptions: {
    marginRight: 3,
    marginBottom: 3,
  },
  usersWrapper: {
    flexWrap: "wrap",
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal",
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  optionWrapper: {
    padding: "50px 30px 50px 30px",
    minWidth: 440,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: 300,
    },
  },

  contentTitle: {
    fontSize: 14,
  },
  optionContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 40,
  },
  optionPaper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  optionPaperActive: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
  },
  optionBox: {
    textAlign: "center",
  },
  optionBoxTitle: {
    fontSize: 12,
    lineHeight: "14px",
  },
  optionBoxContent: {
    fontSize: 10,
  },
  avatarBox: {
    margin: "0 auto",
    marginBottom: 10,
  },
  avatarType1: {
    backgroundColor: "#78c1ce",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType2: {
    backgroundColor: "#efc945",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType3: {
    backgroundColor: "#f39a3e",
    "& svg": {
      color: "#ffffff",
    },
  },
  actionBox: {
    textAlign: "right",
  },
  buttonBegin: {
    textTransform: "Capitalize",
    backgroundColor: "#4f98bc",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#4d90b2",
    },
  },
  buttonBeginLink: {
    textTransform: "Capitalize",
    fontWeight: "normal",
    fontSize: 14,
    display: "inline-block",
    borderRadius: 4,
    minWidth: 100,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    textDecoration: "none",
    color: "#ffffff",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
    },
  },
  rootBottomNav: {
    width: "80%",
    marginRight: "15%",
    borderTop: "1px solid #d4d4d4",
  },
  containerDisplayFiles: {
    marginTop: 10,
  },
  buttonBackDrawer: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  buttonBack: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  chipAuto: {
    fontSize: "10px !important",
    margin: "3px !important",
    marginRight: "10px !important",
    height: "24px !important",
    marginBottom: "10px !important",
    "& .MuiChip-avatar": {
      marginLeft: "0 !important",
    },
  },
  menuItemIconComment: {
    textAlign: "right",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    margin: 0,
    padding: 0,
    justifyContent: "end",
  },
  menu: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  formContainerInformation: {
    textAlign: "right",
  },
  wrapperDialog: {
    textAlign: "center",
  },
  wrapperDialogClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  contentDialog: {
    fontSize: 14,
  },
  wrapperDialogAction: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  formContainer: {
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  buttonDialog: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    marginLeft: 10,
    fontWeight: "bold",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  buttonOutlinedDialog: {
    textTransform: "Capitalize",
    paddingLeft: 50,
    paddingRight: 50,
    color: "#4f98bc",
    marginRight: 40,
  },
  containerOptions: {
    "& .MuiFormControlLabel-label": {
      fontSize: 14,
      fontColor: "#747474",
      fontWeight: "bold",
    },
  },
  rootBillingHeader: {
    marginTop: theme.spacing(2),
    "& h6": {
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  containerRatingBox: {
    backgroundColor: "#ffffff",
    padding: theme.spacing(1),
    marginBottom: 30,
  },
  containerRatingDetail: {
    paddingTop: 16,
    paddingBottom: 16,
    position: "relative",
  },
  containerRating: {
    marginTop: 12,
    marginBottom: 24,
  },
  buttonRating: {
    background: "#9CBB65 !important",
  },
  ratingEditIcon: {
    position: "absolute",
    top: -28,
    right: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  ratingDetail: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  ratingFont1: {
    backgroundColor: "#F07A8C",
    color: "#ffffff",
  },
  ratingFont2: {
    backgroundColor: "#F5A6B5",
    color: "#ffffff",
  },
  ratingFont3: {
    backgroundColor: "#EFC945",
    color: "#ffffff",
  },
  ratingFont4: {
    backgroundColor: "#9CBB65",
    color: "#ffffff",
  },
  ratingFont5: {
    backgroundColor: "#328914",
    color: "#ffffff",
  },
  ratingBox: {
    background: "#FFFFFF",
    borderRadius: 3,
    width: 35,
  },
  ratingFont: {
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "35px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  ratingScore: {
    paddingLeft: 16,
    fontSize: 14,
  },
  ratingScoreName: {
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 2,
  },
  ratingCommentContainer: {
    padding: 12,
    backgroundColor: "#F8F8F8",
  },
  wrapperNameUser: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: 14,
    backgroundColor: "#DFDFDF",
    color: "#747474",
  },
  nameRatingContainer: {
    fontWeight: "bold",
    fontSize: 14,
    paddingLeft: 8,
  },
  ratingHeaderDetailContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 12,
  },
  ratingDetailDate: {
    fontSize: 14,
  },
  ratingDetailComment: {
    fontSize: 14,
  },
  popover: {
    pointerEvents: "none",
  },
  containerTabs: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
  },
  buttonTabActive: {
    backgroundColor: "#4f98bc !important",
    color: "#FFFFFF !important",
  },
  buttonTab: {
    display: "flex",
    alignItems: "center",
    textTransform: "Capitalize",
    verticalAlign: "middle",
    fontWeight: "bold",
    fontSize: 12,
    minWidth: 100,
    padding: 8,
    backgroundColor: "#EDF5F8",
    color: "#4F98BC",
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#c2d6e1",
    },
  },
  inputNewComments: {
    border: "1px solid #dfdfdf",
    padding: "8px !important",
    borderRadius: 4,
  },
}));

export default useStyles;
