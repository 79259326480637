import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ViewButton from "./rowViewButton";
import Box from "@material-ui/core/Box";
import { timeFormat24toAMPM } from "../../util/timeFormat";
import { get } from "lodash";
import useStyles from "./styles";

import { useTableState } from "contexts/tableContext";

const TableMobile = (props) => {
  const classes = useStyles();

  const { rows, columns } = useTableState();

  const createKey = (item, column) => {
    return `${item.id}-${column.id}`;
  };

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const renderCell = (item, column) => {
    if (column.contentMobile && !column.format)
      return column.contentMobile(item);
    if (column.content && !column.format) return column.content(item);

    if (column.format === "date") {
      const dateField = get(item, column.id);
      return dateField && dateField !== "0000-00-00"
        ? moment(dateField).format("MM/DD/YYYY")
        : "-";
    }

    if (column.format === "id") {
      return (
        <Box className={classes.viewActionWrap}>
          <Box>{get(item, column.id)}</Box>
          {Boolean(column.path) && (
            <Link
              className={classes.viewActionMobile}
              to={{
                pathname: `/${column.path ? column.path : "project"}/${
                  item.id
                }`,
              }}
            >
              <ViewButton />
            </Link>
          )}
        </Box>
      );
    }

    if (column.format === "link") {
      return (
        <Link className={classes.linkFormat} to={column.path(item)}>
          {column.content ? column.content(item) : get(item, column.id)}
        </Link>
      );
    }

    if (column.format === "link_external") {
      return (
        <a
          className={classes.linkFormat}
          href={column.path(item)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {column.content ? column.content(item) : get(item, column.id)}
        </a>
      );
    }

    if (column.format === "time") {
      return (
        <Typography variant="body2" color="inherit" noWrap>
          {timeFormat24toAMPM(get(item, column.id))}
        </Typography>
      );
    }

    if (column.format === "main") {
      return (
        <Typography variant="body1" color="inherit" noWrap>
          {get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "number") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {get(item, column.id)}
        </Typography>
      );
    }

    if (column.format === "number_comma") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {Number(get(item, column.id)).toLocaleString(undefined, {
            maximumFractionDigits: 0,
          })}
        </Typography>
      );
    }

    if (column.format === "percentage") {
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {`${formatNumber(parseFloat(get(item, column.id)).toFixed(2))} %`}
        </Typography>
      );
    }

    if (column.format === "money") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(parseFloat(get(item, column.id)).toFixed(2))}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }

    if (column.format === "money_int") {
      const value =
        get(item, column.id) && get(item, column.id) !== ""
          ? `$ ${formatNumber(parseFloat(get(item, column.id)).toFixed(0))}`
          : "-";
      return (
        <Typography
          variant="body2"
          className={classes.money}
          color="inherit"
          noWrap
        >
          {value}
        </Typography>
      );
    }

    return get(item, column.id);
  };

  const clickRow = (event, id) => {
    if (props.handleRowClick) {
      props.handleRowClick(event, id);
    }
  };

  const dataRows = rows;

  if (dataRows && dataRows.length > 0) {
    return dataRows.map((item, index) => {
      return (
        <Grid
          container
          spacing={4}
          key={item.id}
          className={classes.rowItem}
          onClick={(e) => clickRow(e, item.id)}
        >
          {columns.map((column, index) => (
            <React.Fragment key={createKey(item, column)}>
              <Grid item xs={4} className={classes.item}>
                {column.label}
              </Grid>
              <Grid item xs={8} className={classes.itemValue}>
                {renderCell(item, column)}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      );
    });
  } else {
    return <div>No Records Found</div>;
  }
};

export default TableMobile;
