import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    overflowY: "hidden",
  },
  rootTable: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  header: {
    marginBottom: 10,
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: 10,
  },
  containerMain: {
    backgroundColor: "#f8f8f8",
    height: "90vh",
    position: "relative",
    overflow: "hidden",
  },
  containerInformation: {
    padding: theme.spacing(2),
    width: "100%",
    height: "90vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
      paddingBottom: 30,
    },
  },
  containerInformationCreate: {
    padding: 20,
    width: "100%",
  },
  containerInformationActions: {
    backgroundColor: "#ececec",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 2,
    paddingRight: 2,
    display: "flex",
    position: "absolute",
    width: "100%",
    bottom: 0,
    "& button": {
      padding: "7px 5px 7px 5px",
    },
  },
  containerBody: {
    padding: 20,
  },
  containerSidebar: {
    backgroundColor: "#f8f8f8",
    padding: 20,
    paddingBottom: 80,
  },
  titleBody: {
    fontSize: 24,
    fontWeight: "bold",
  },
  subtitleBody: {
    fontSize: 18,
    fontWeight: "normal",
  },
  containerSubtitle: {
    "& > span:last-child": {
      borderRight: "0 !important",
    },
  },
  containerSubtitleItem: {
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
    paddingRight: 5,
    borderRight: "1px solid #747474",
  },
  containerTimekeeping: {
    backgroundColor: "#ffffff",
    padding: 10,
    marginBottom: 30,
  },
  containerTimekeepingClock: {
    paddingTop: 40,
    paddingBottom: 40,
    position: "relative",
  },
  containerBodyTaskList: {
    marginTop: 40,
  },
  containerActionButtons: {
    marginTop: 10,
    marginBottom: 30,
  },
  button: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
  },
  buttonLink: {
    textTransform: "Capitalize",
    fontWeight: "bold",
    fontSize: 14,
    width: "100%",
    display: "block",
    borderRadius: 4,
    minWidth: 64,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
      backgroundColor: "rgba(23, 69, 122, 0.08)",
    },
  },
  buttonOutlined: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonOutlinedSuccess: {
    color: "#9cbb65",
    background: "#ffffff",
  },
  buttonPrimary: {
    background: "#4f98bc !important",
  },
  buttonPrimaryAction: {
    background: "#78c1ce !important",
    textTransform: "Capitalize",
    marginRight: 20,
  },
  buttonPin: {
    background: "#8f0aa1 !important",
    "&::after": {
      top: "97%",
      left: 20,
      border: "solid transparent",
      content: "''",
      height: "0",
      width: "0",
      position: "absolute",
      borderColor: "rgba(117, 204, 248, 0)",
      borderTopColor: "#8f0aa1",
      borderWidth: "8px",
      marginLeft: "-8px",
      zIndex: 5,
    },
  },
  buttonAdd: {
    background: "#4f98bc !important",
    width: 160,
  },
  buttonUpload: {
    background: "#4f98bc !important",
    width: 160,
    marginTop: 10,
  },
  containerUploadFile: {
    textAlign: "right",
  },
  containerSignature: {
    padding: 10,
    marginBottom: 30,
  },
  containerLocation: {
    padding: 10,
  },
  containerLocationBtn: {
    marginTop: 30,
  },
  iconLarge: {
    fontSize: 36,
  },
  textTimer: {
    fontSize: 18,
    fontWeight: "normal",
    marginTop: 18,
    marginLeft: 5,
  },
  taskItemFieldset: {
    width: "100%",
  },
  taskItemContainer: {
    marginTop: "10px !important",
    "& > label:last-child": {
      borderBottom: "1px solid #f8f8f8",
    },
  },
  taskItem: {
    borderTop: "1px solid #f8f8f8",
    marginLeft: "0 !important",
    marginRight: "0 !important",
    width: "100%",
    "& > span": {
      fontSize: 14,
    },
    "& > .MuiCheckbox-root": {
      color: "#c4c4c4",
    },
  },
  taskItemAdd: {
    backgroundColor: "#ececec",
    "& > .MuiFormControlLabel-label": {
      width: "100%",
    },
  },
  boxActions: {
    marginTop: 10,
    textAlign: "right",
  },
  inputNewTask: {
    width: "100%",
    "& > input": {
      backgroundColor: "#ececec",
      width: "100%",
    },
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: "20px !important",
    textTransform: "capitalize",
    fontSize: 14,
    minWidth: "100px !important",
    "&.Mui-selected": {
      color: "#4f98bc",
      fontWeight: "bold",
    },
    "& > span": {
      fontSize: 14,
    },
  },
  containerComments: {
    marginTop: 20,
  },
  inputNewComment: {
    border: "1px solid #dfdfdf",
    padding: "10px !important",
  },
  containerActivity: {
    marginTop: 20,
    maxHeight: 500,
    overflowY: "auto",
  },
  groupOption: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOption2: {
    color: "#4f98bc",
    width: "200px",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  wrapperIcon: {
    margin: 0,
    padding: 0,
    marginLeft: 5,
    marginRight: 5,
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
  iconOptions: {
    width: 18,
    height: 18,
  },
  titleDialog: {
    textAlign: "center",
    marginBottom: 20,
    fontSize: 18,
    "& > svg": {
      position: "relative",
      bottom: "-5px",
    },
  },
  sigCanvas: {
    backgroundColor: "#f8f8f8",
    width: "100%",
    height: 85,
  },
  actionsDialogWrapper: {
    justifyContent: "center !important",
    marginTop: 20,
    marginBottom: 20,
    "& > button": {
      minWidth: 140,
      marginRight: 40,
      marginLeft: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
      "& > button": {
        minWidth: 100,
        marginRight: 20,
        marginLeft: 20,
      },
    },
  },
  containerStatus: {
    display: "inline-block",
    top: "-3px",
    position: "relative",
    marginLeft: 10,
    "& > .MuiChip-root": {
      height: 21,
      borderRadius: 3,
      fontSize: 10,
      minWidth: 110,
      marginLeft: 5,
      fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: -5,
    },
  },
  chipPrimary: {
    backgroundColor: "#78c1ce !important",
    color: "#fff !important",
  },
  chipDark: {
    backgroundColor: "#747474 !important",
    color: "#fff !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "#747474 !important",
    fontWeight: "bold !important",
    marginBottom: 5,
  },
  inputLabelP: {
    fontSize: "14px !important",
    color: "#747474 !important",
    fontWeight: "bold !important",
    marginBottom: 5,
  },
  containerPriority: {
    marginTop: 10,
    marginBottom: 15,
  },
  containerOptions2: {
    marginTop: 10,
    marginBottom: 30,
  },
  dialogHeader: {
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        margin: "0 auto",
      },
    },
  },
  containerDialog: {
    minHeight: 400,
  },
  tableDialog: {
    marginTop: 30,
    "& td": {
      border: "none",
    },
    "& th": {
      border: "none",
      background: "#ffffff",
    },
    "& tr:nth-child(odd)": {
      background: "#f8f8f8",
    },
  },
  tableHeadDialog: {
    "& .MuiTableCell-head": {
      fontWeight: "bold",
    },
  },
  formContainerDialog: {
    padding: "0 75px 0 75px",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  formIconDialog: {
    textAlign: "center",
  },
  formTitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  formSubtitleDialog: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "normal",
    marginBottom: 10,
  },
  containerFile: {
    backgroundColor: "#ffffff",
    padding: 10,
    marginBottom: 30,
  },
  avatarComment: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  listName: {
    fontWeight: "bold",
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
    },
  },
  listText: {
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      display: "block",
      width: "100%",
      paddingRight: 0,
    },
  },
  listAction: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      clear: "both",
      position: "relative !important",
      textAlign: "right",
    },
  },
  listContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important",
    },
  },
  scheduleViewTitle: {
    fontSize: 18,
  },
  scheduleViewDateTitle: {
    fontSize: 14,
    fontWeight: "normal",
  },
  scheduleBadge: {
    width: 11,
    height: 11,
    backgroundColor: "#78c1ce",
    borderRadius: 2,
    position: "absolute",
    top: 12,
    left: 20,
  },
  imgSignature: {
    maxWidth: "100%",
  },
  avatarOptions: {
    marginRight: 3,
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    minWidth: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal",
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontWeight: "normal",
  },
  optionWrapper: {
    padding: "50px 30px 50px 30px",
  },
  contentTitle: {
    fontSize: 14,
  },
  optionContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 10,
  },
  optionPaper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  optionPaperActive: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
  },
  optionPaperBig: {
    paddingTop: 20,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  optionPaperActiveBig: {
    paddingTop: 20,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
  },
  optionGrid: {
    padding: "12px !important",
  },
  optionBox: {
    textAlign: "center",
  },
  optionBoxTitle: {
    fontSize: 12,
    lineHeight: "14px",
  },
  optionBoxContent: {
    fontSize: 10,
  },
  avatarBox: {
    margin: "0 auto",
    marginBottom: 10,
  },
  avatarType1: {
    backgroundColor: "#ffffff",
    "& svg": {
      color: "#4f98bc",
    },
  },
  actionBox: {
    textAlign: "right",
  },
  buttonBegin: {
    textTransform: "Capitalize",
    backgroundColor: "#4f98bc",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "#4d90b2",
    },
  },
  buttonBeginLink: {
    textTransform: "Capitalize",
    fontWeight: "normal",
    fontSize: 14,
    float: "right",
    borderRadius: 4,
    minWidth: 100,
    lineHeight: 1.75,
    border: "1px solid rgba(23, 69, 122, 0.5)",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    textDecoration: "none",
    color: "#ffffff",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
    },
  },
  rootBottomNav: {
    width: "85%",
    marginRight: "15%",
    borderTop: "1px solid #d4d4d4",
  },
  containerDisplayFiles: {
    marginTop: 10,
  },
  containerOptions: {
    marginBottom: 0,
  },
  containerTabs: {
    paddingLeft: 25,
    paddingRight: 25,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  rootTabs: {
    borderBottom: "1px solid #f8f8f8",
    "& .MuiTab-root": {
      minWidth: 80,
    },
  },
  tabContentSectionHeader: {
    padding: theme.spacing(2),
  },
  avatarType01: {
    backgroundColor: "#9cbb65",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType2: {
    backgroundColor: "#efc945",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType3: {
    backgroundColor: "#f39a3e",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType4: {
    backgroundColor: "#78c1ce",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType5: {
    backgroundColor: "#F07A8C",
    "& svg": {
      color: "#ffffff",
    },
  },
  actionBox2: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  buttonEnd: {
    textTransform: "Capitalize",
    backgroundColor: "transparent",
    minWidth: 100,
    "&:hover": {
      backgroundColor: "transparent",
    },
    color: "#4f98bc",
  },
  buttonEndLink: {
    textTransform: "Capitalize",
    fontWeight: "normal",
    fontSize: 14,
    display: "inline-block",
    borderRadius: 4,
    minWidth: 100,
    lineHeight: 1.75,
    border: "none",
    paddingTop: 8,
    paddingBottom: 8,
    textAlign: "center",
    textDecoration: "none",
    color: "#4f98bc",
    paddingLeft: 15,
    paddingRight: 15,
    "&:hover": {
      textDecoration: "none !important",
    },
  },
  groupOptionPriority: {
    color: "#4f98bc",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  containerTabActivity: {
    height: "75vh",
    overflowY: "auto",
  },
  contentCounter: {
    textAlign: "right",
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
  },
  itemDialog: {
    padding: theme.spacing(1),
  },
  buttonConfirm: {
    fontWeight: "bold",
    marginTop: "22px",
    color: "#ffffff",
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
  chipAsset: {
    marginRight: 10,
    marginBottom: 5,
    background: "#9dba65",
    color: "#ffffff",
    borderRadius: 4,
    fontSize: 12,
    "& svg": {
      color: "#ffffff",
    },
  },
  containerSelectedElements: {
    maxWidth: 350,
  },
  containerAssetInput: {
    "& > div": {
      marginBottom: 10,
    },
    "& input": {
      fontSize: 14,
    },
  },
  avatarHistory: {
    fontSize: 14,
    color: "#747474",
    fontWeight: "bold",
    backgroundColor: "#dfdfdf",
  },
  containerhistory: {
    minHeight: 275,
    maxHeight: 275,
    overflowY: "scroll",
  },
  containerTabWorkticketProject: {
    marginTop: theme.spacing(1),
    "& table": {
      minWidth: "900px !important",
    },
    "& div[class*='contentCounter-']": {
      marginTop: "-25px !important",
      right: "50px !important",
    },
    "& div[class*='fullHeightTable']": {
      maxHeight: "75% !important",
    },
    "& .Mui-selected": {
      color: "#747474 !important",
    },
    "& .MuiTabs-root": {},
  },
  buttonBack: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  checkListIconAvailable: {
    "& svg": {
      color: "#9cbb65 !important",
    },
  },
  checkListIconAvailableText: {
    "& span": {
      color: "#747474 !important",
    },
  },
  checkListIconNotAvailable: {
    "& svg": {
      color: "#f07a8c !important",
    },
  },
  checkListIconNotAvailableText: {
    "& span": {
      color: "#747474 !important",
      textDecoration: "line-through !important",
    },
  },
  checkListIconOptional: {
    "& svg": {
      color: "#0b417e !important",
    },
  },
  checkListIconOptionalText: {
    "& span": {
      color: "#747474 !important",
    },
  },
  contentContainer: {
    padding: "0 20px",
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
  },
  fullHeightTableProjectQuote: {
    flex: "1 1",
    display: "flex",
    maxHeight: "75%",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 800,
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "90%",
    },
  },
  fullHeightTableProject: {
    flex: "1 1",
    display: "flex",
    maxHeight: "75%",
    maxWidth: "100%",
    overflowX: "hidden",
    "& table": {
      minWidth: 500,
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "90%",
    },
  },
  wrapperJobFilter: {
    position: "relative",
  },
  btnJobFilter: {
    textTransform: "capitalize",
    position: "absolute",
    top: -2,
    right: 0,
  },
  btnJobApply: {
    textTransform: "capitalize",
    minWidth: 150,
  },
  appBarWorkticketPreview: {
    position: "relative",
  },
  appBarTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "right",
  },
  filterJobContent: {
    padding: theme.spacing(2),
  },
  filterJobTableTitle: {
    fontWeight: "bold",
    "& svg": {
      position: "relative",
      bottom: -8,
    },
  },
  filterJobTableHeader: {
    "& th": {
      fontWeight: "bold",
    },
  },
  filterJobTableCol: {
    width: 40,
  },
  filterState: {
    minWidth: 300,
    float: "right",
    "& > div": {
      marginBottom: 0,
    },
  },
  jobsTableCounter: {
    float: "left",
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
    width: "50%",
  },
  collapseInfo: {
    maxWidth: "4%",
    flexBasis: "4%",
    animation: "$animationSidebar 0.5s 1 ease-in-out",
  },
  collapseSidebar: {
    maxWidth: "96%",
    flexBasis: "96%",
    animation: "$animationMain 0.5s 1 ease-in-out",
  },
  unCollapseInfo: {
    maxWidth: "25%",
    flexBasis: "25%",
    animation: "$animationSidebarUn 0.5s 1 ease-in-out",
  },
  unCollapseSidebar: {
    maxWidth: "75%",
    flexBasis: "75%",
    animation: "$animationMainUn 0.5s 1 ease-in-out",
  },
  collapseIcon: {
    top: 10,
    right: 15,
    position: "absolute",
    cursor: "pointer",
  },
  "@keyframes animationSidebar": {
    "0%": {
      maxWidth: "25%",
      flexBasis: "25%",
    },
    "100%": {
      maxWidth: "4%",
      flexBasis: "4%",
    },
  },
  "@keyframes animationMain": {
    "0%": {
      maxWidth: "75%",
      flexBasis: "75%",
    },
    "100%": {
      maxWidth: "96%",
      flexBasis: "96%",
    },
  },
  "@keyframes animationSidebarUn": {
    "0%": {
      maxWidth: "4%",
      flexBasis: "4%",
    },
    "100%": {
      maxWidth: "25%",
      flexBasis: "25%",
    },
  },
  "@keyframes animationMainUn": {
    "0%": {
      maxWidth: "96%",
      flexBasis: "96%",
      left: "40%",
    },
    "100%": {
      maxWidth: "75%",
      flexBasis: "75%",
      left: "60%",
    },
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  centerLoadingSideBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1",
    margin: 0,
    padding: 0,
    height: "80vh",
    overflowY: "hidden",
  },
}));

export default useStyles;
