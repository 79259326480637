import http from "./httpService";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";

const apiEndpoint = `${apiUrl}/projects`;
const apiEndpointSingular = `${apiUrl}/project`;
const apiEndpointPreventative = `${apiUrl}/preventative`;

export function getProjects(filterData, tab) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}filters[tab]=${tab}`);
}

export function getProjectsAB(filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}-ab?${filterString}`);
}

export function getProjectsSearch(search, filterData, tab) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&search=${search}`
  );
}

export function getProjectsTabOffset(tab, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}`
  );
}

export function getProjectDetails(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function getProjectsUser() {
  return http.get(`${apiEndpoint}/user`);
}

export function updateProjectDetails(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function createProject(data) {
  return http.post(`${apiEndpointSingular}`, data);
}

export function createProjectPreventative(data) {
  return http.post(`${apiEndpointSingular}/preventative`, data);
}

export function addJobsProjectPreventative(id, data) {
  return http.post(`${apiEndpoint}/${id}/preventative/jobs/add`, data);
}

export function removeJobsProjectPreventative(id, data) {
  return http.post(`${apiEndpoint}/${id}/preventative/jobs/remove`, data);
}

export function updateProjectBulkStatus(data) {
  return http.put(`${apiEndpoint}/bulk-status`, data);
}

export function deleteProject(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}
export function getQuotes(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/quotes?${filterString}`);
}

export function getQuotesOffset(id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/quotes?${filterString}&offset=${offset}`
  );
}

export function getQuotesSearch(id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/quotes?${filterString}search=${search}`
  );
}

export function getFinancialAPOperations(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/ap-billing?${filterString}`);
}

export function getFinancialAROperations(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/ar-billing?${filterString}`);
}

export function getFinancialPayments(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/payments?${filterString}`);
}

export function getFinancialPaymentsOffset(id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/payments?${filterString}offset=${offset}`
  );
}

export function getFinancialPaymentsSearch(id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/payments?${filterString}search=${search}`
  );
}

export function getFinancialBills(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/bills?${filterString}`);
}

export function getFinancialBillsOffset(id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/bills?${filterString}&offset=${offset}`
  );
}

export function getFinancialBillsSearch(id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/bills?${filterString}search=${search}`);
}

export function getAssets(id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${id}/assets?${filterString}`);
}

export function getAssetsOffset(id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/assets?${filterString}&offset=${offset}`
  );
}

export function getAssetsSearch(id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${id}/assets?${filterString}search=${search}`
  );
}

export function getTeamMembers(job_id) {
  return http.get(`${apiEndpointSingular}/team-members?job_id=${job_id}`);
}

export function getProjectServices(project_id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}/${project_id}/services?${filterString}`);
}

export function getProjectServicesOffset(project_id, offset, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${project_id}/services?${filterString}offset=${offset}`
  );
}

export function getProjectServicesSearch(project_id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/${project_id}/services?${filterString}search=${search}`
  );
}

export function getPreventativeServices(project_id) {
  return http.get(`${apiEndpointPreventative}/${project_id}/service`);
}

export function getPreventativeServiceItem(service_id) {
  return http.get(`${apiEndpointPreventative}/services/${service_id}`);
}

export function createPreventativeServices(project_id, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpointPreventative}/${project_id}/service`,
    setFormData(data)
  );
}

export function updatePreventativeServiceItem(service_id, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpointPreventative}/services/${service_id}`,
    setFormData(data)
  );
}

export function previewPreventativeServices(data) {
  return http.post(`${apiEndpointPreventative}/service/preview`, data);
}

export function deletePreventativeServices(service_id) {
  return http.delete(`${apiEndpointPreventative}/services/${service_id}`);
}

export function stopPreventativeServices(service_id, data) {
  return http.post(
    `${apiEndpointPreventative}/services/${service_id}/recurrence/stop`,
    data
  );
}

export function restartPreventativeServices(service_id, data) {
  return http.post(
    `${apiEndpointPreventative}/services/${service_id}/recurrence/restart`,
    data
  );
}

export function getActivities(id) {
  return http.get(`${apiEndpoint}/${id}/activities`);
}

export function getActivitiesOffset(id, offset) {
  return http.get(`${apiEndpoint}/${id}/activities?offset=${offset}`);
}
