import React, { useContext } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GlobalUiContext from "contexts/globalUiContext";
import WorkticketCreate from "pages/workticketPage/workticketCreateDrawer";
import WorkticketCreateSurface from "pages/workticketPage/workticketCreateDrawerSurface";
import ProjectCreate from "pages/projectPage/projectCreateDrawer";
import QuoteCreate from "pages/quotePage/quoteCreateDrawer";
import RequestFacility from "pages/requestFacility/requestFacility";
import BudgetUpload from "pages/budgetPage/budgetUploadDrawer";
import WorknovaUpload from "pages/worknovaList/worknovaUploadDrawer";
import useStyles from "./styles";

const SideDrawer = (props) => {
  const classes = useStyles();
  const { globalUi, dispatchGlobalUi } = useContext(GlobalUiContext);
  const { drawerContent, isDrawerOpen } = globalUi;

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      className={classes.drawerWrapper}
    >
      <div>
        <IconButton
          className={classes.buttonClose}
          aria-label="Close"
          onClick={() =>
            dispatchGlobalUi({ type: "TOGGLE_SIDEDRAWER", isDrawerOpen: false })
          }
        >
          <CloseIcon className={classes.iconClose} />
        </IconButton>
        {drawerContent === "project" && <ProjectCreate />}
        {drawerContent === "workticket" && <WorkticketCreate />}
        {drawerContent === "workticketSurface" && <WorkticketCreateSurface />}
        {drawerContent === "quote" && <QuoteCreate />}
        {drawerContent === "request" && <RequestFacility />}
        {drawerContent === "budget" && <BudgetUpload />}
        {drawerContent === "worknova" && <WorknovaUpload />}
      </div>
    </Drawer>
  );
};

export default SideDrawer;
