import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputField: {
    flexGrow: 1,
    width: 300,
    maxWidth: 300,
    minWidth: 300,
    height: 42,
    backgroundColor: "white",
    borderRadius: 4,
    border: "solid 1px #d6dde1",
    "& .Mui-focused": {
      border: "solid 1px #359be0",
    },
    "& > input": {
      padding: 8,
    },
    "& > fieldset": {
      border: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: 160,
      maxWidth: 160,
      minWidth: 160,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      clear: "both",
      marginTop: 10,
      zIndex: 100,
      width: "100%",
      maxWidth: "100%",
    },
  },
  searchIcon: {
    color: "#ececec",
    cursor: "pointer",
  },
  clearSearch: {
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    cursor: "pointer",
  },
  clearSearchButton: {
    padding: 0,
  },
}));

export default useStyles;
