import React from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";

import { useTableState } from "../../../contexts/tableContext";
import MaintenanceAlert from "components/common/maintenanceAlert";

const EnhancedTableToolbar = (props) => {
  const classes = useStyles();
  const { selected } = useTableState();

  return (
    <>
      <MaintenanceAlert />
      <Toolbar
        className={clsx(classes.toolBar, {
          [classes.highlight]: selected.length > 0,
        })}
      >
        {props.children}
      </Toolbar>
    </>
  );
};

export default EnhancedTableToolbar;
